import { CLOUDINARY_CORE_INIT_OPTIONS, CLOUDINARY } from 'services/constants'
import cloudinary from 'cloudinary-core'

export function getCloudinaryPublicId(url = '') {
  const deleteTillMatch = (r, str) => {
    return r.substring(r.indexOf(str) + str.length)
  }

  const unwantedS3FilePath = 'assets.aedit.com'
  const unwantedContentfulFilePath = 'images.ctfassets.net/zw35sfestf1t'
  let isContentfulImage = false

  if (url.includes(unwantedS3FilePath)) {
    url = deleteTillMatch(url, unwantedS3FilePath)
  } else if (url.includes(unwantedContentfulFilePath)) {
    url = deleteTillMatch(url, unwantedContentfulFilePath)
    isContentfulImage = true
  }

  return `${isContentfulImage ? '/contentful' : ''}${url}`
}

export const cloudinaryImage = (relativePath, options = {}) => {
  if (!relativePath) {
    return null
  }

  const { hasWatermark, serveOriginal, ...params } = options
  if (serveOriginal) {
    return `${CLOUDINARY}/${relativePath}`
  }

  const cl = new cloudinary.Cloudinary(CLOUDINARY_CORE_INIT_OPTIONS)
  const transformation = [params]

  if (hasWatermark) {
    transformation.push({
      overlay: 'watermark_logo_bottom_right_mtznud',
      width: '100',
      gravity: 'north_east',
      x: 20,
      y: 20,
    })
    transformation.push({
      overlay: 'watermark_logo_center_hgxhld',
      width: '100',
      gravity: 'center',
    })
  }

  return cl.url(getCloudinaryPublicId(relativePath), { ...transformation[0], transformation })
}
